import axios from 'axios';
import React, { useEffect, useState } from 'react'; 
import { AmbikaChefAPI } from './config';

const ProductionReadyReport = () => {
    const [productionReady, setProductionReady] = useState([]);  
    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');

    const getProductionReadyList = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/reportProductionReadyProductList`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setProductionReady(res?.data?.data); 
            })
            .catch((err) => {
                // Handle error
            });
    }


    useEffect(() => {
        getProductionReadyList()
    }, [ambikaChefUserToken, ambikaChefUserId]);
 
 



    const showAllProduct = (placeOrder) => {
        return placeOrder?.map((order, index) => (
            <div key={index} className="text-start mt-4">
                <div className="d-flex align-items-center justify-content-between" style={{ fontSize: '14px' }}>
                    <p class='fw-semibold'>{order.pickup_date} | {order.pickup_time}</p>
                    <p className="fw-semibold"># {order.order_id}</p>
                </div>
                {order.product_detail?.map((cat, catInd) => {
                    return (
                        <div class='boxSadow1 rounded-3 p-2'>
                            <div class='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className="fw-semibold ">{cat?.productName} <span className=' text-secondary' style={{ fontSize: '12px' }}>{cat?.size_name}</span></p>
                                </div>
                                <div>
                                    <p className="fw-semibold "> <span>{cat?.product_qty}</span> </p>
                                </div>
                            </div>
                            <div className=' border-top'>
                                <div className="d-flex gap-2 align-items-center mt-2">
                                    {cat?.is_urgent !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Urgent
                                        </p>
                                    )}
                                    {cat?.is_parcel !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Packing
                                        </p>
                                    )}
                                    {cat?.eggless_option !== '' && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Less Cream
                                        </p>
                                    )}
                                </div>
                                {(cat?.shap_option !== "" || cat?.message_option !== "") &&
                                    <div className="d-flex gap-2 align-items-center mt-2">
                                        {
                                            cat?.shap_option !== "" && (
                                                <p class='text-secondary'>{cat?.shap_option}</p>
                                            )
                                        }
                                        {
                                            (cat?.shap_option !== "" && cat?.message_option !== "") && (
                                                <p class='text-secondary'>|</p>
                                            )
                                        }
                                        {
                                            cat?.message_option !== "" && (
                                                <p class='text-secondary'>{cat?.message_option}</p>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    )
                })} 
            </div>
        ));
    };
  

    return (
        <div>
            
            <div className="col-lg-6 col-md-8 col-11 m-auto mt-4">
                {
                    productionReady != '' ?
                        showAllProduct(productionReady)
                        :
                        <div>
                            <br />
                            <p class='fs-1 mt-4'>No Product Found...</p>
                        </div>
                }
                <br />
            </div>
        </div>
    );
};

export default ProductionReadyReport;
