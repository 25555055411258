import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => { 
  const ambikaChefUserToken = localStorage.getItem("ambikaChefUserToken");
  const ambikaChefUserId = localStorage.getItem("ambikaChefUserId");

  if (!ambikaChefUserToken && !ambikaChefUserId ) {
    return <Navigate to="/log-in" />;
  }

  return <>{children}</>;
};
