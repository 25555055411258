import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const SendForProduction = ({update}) => {
    const [SendProduction, setSendProduction] = useState([]); 
    const [showProductionredy, setShowProductionRedy] = useState(false)  
    const [orderProduction, setOrderProduction] = useState()  
    const [orderRemark, setOrderRemark] = useState()
    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId'); 

    const sendForProductionList = () => {
        axios
        .post(
            'https://www.erp.ambikadairyfarm.com/api/chef/sendForProductionProductList',
            { UserId: ambikaChefUserId },
            { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
        )
        .then((res) => {
            setSendProduction(res?.data?.data);
        })
        .catch((err) => {
            // Handle error
        });
    }

    useEffect(() => {
        sendForProductionList()
    }, [ambikaChefUserToken, ambikaChefUserId]); 

    const handelProductionRedy = (orderId, productId) => {
        setOrderProduction((prevDetails) => ({
            ...prevDetails,
            orderId: orderId,
            orderProductId: productId,
        }));
        setShowProductionRedy(true)
    }; 

    const showAllProduct = () => {
        return SendProduction?.map((order, index) => (
            <div key={index} className="text-start mt-4">
                <div className="d-flex align-items-center justify-content-between" style={{fontSize:'14px'}}>
                    <p class='fw-semibold'>{order.pickup_date} | {order.pickup_time}</p>
                    <p className="fw-semibold"># {order.order_id}</p>
                </div>
                <div  class='boxSadow1 rounded-3'> 
                {order.product_detail.map((cat, catIndex) => (
                    <div class='p-3 border-bottom border-2 border-dark-subtle'>
                        <div class='d-flex align-items-center justify-content-between'>
                            <div>
                                <p className="fw-semibold ">{cat?.productName} <span className=' text-secondary' style={{ fontSize: '12px' }}>{cat?.size_name}</span></p>
                            </div>
                            <div>
                                <p className="fw-semibold "> <span>{cat?.product_qty}</span> </p>
                            </div>
                        </div>
                        <div className=' border-top'>
                            <div className="d-flex gap-2 align-items-center mt-2">
                                {cat?.is_urgent !== 0 && (
                                    <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                        Urgent
                                    </p>
                                )}
                                {cat?.is_parcel !== 0 && (
                                    <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                        Packing
                                    </p>
                                )}
                                {cat?.eggless_option !== '' && (
                                    <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                        Less Cream
                                    </p>
                                )}
                            </div>
                            {(cat?.shap_option !== "" || cat?.message_option !== "") &&
                                <div className="d-flex gap-2 align-items-center mt-2">
                                    {
                                        cat?.shap_option !== "" && (
                                            <p class='text-secondary'>{cat?.shap_option}</p>
                                        )
                                    }
                                    {
                                        (cat?.shap_option !== "" && cat?.message_option !== "") && (
                                            <p class='text-secondary'>|</p>
                                        )
                                    }
                                    {
                                        cat?.message_option !== "" && (
                                            <p class='text-secondary'>{cat?.message_option}</p>
                                        )
                                    }
                                </div>
                            }
                        </div>
                         <div class='d-flex align-items-center gap-3 my-2'>
                         <button class='btn btn-success rounded-5 form-control' style={{ fontSize: '14px' }} onClick={() => handelProductionRedy(order?.order_id, cat?.orderProduct_id )}>Production Ready</button>
 
                     </div>
                    </div>
                
                ))}
                </div>  
            </div>
        ));
    };
    const handelProductionRedySend = () => {
        axios
            .post(
                'https://www.erp.ambikadairyfarm.com/api/chef/productionReadyProduct',
                { UserId: ambikaChefUserId, ...orderProduction, remarks: orderRemark },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setShowProductionRedy(false)
                setOrderRemark('')
                Swal.fire({
                    text: `${res?.data?.message}`,
                });
                sendForProductionList()
                update()
            })
            .catch((err) => {
                // Handle error
            });
    }  
    return (
        <div>
            {
                showProductionredy === true &&
                <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', position: 'fixed', top: '0', left: '0', zIndex: '10' }} class='d-flex align-items-center justify-content-center'>
                    <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                        <p class='fs-2'>Production Redy</p>
                        <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e) => setOrderRemark(e.target.value)} />
                        <div class='d-flex gap-3'>
                            <button class='btn btn-success mt-3 form-control rounded-5' onClick={() => handelProductionRedySend()}> Save</button>
                            <button class='btn  btn-outline-danger mt-3 form-control rounded-5' onClick={() => setShowProductionRedy(false)}> Close</button>
                        </div>
                    </div>

                </div>
            }

           
            <div className="col-lg-6 col-md-8 col-11 m-auto mt-4">
                {
                    SendProduction != '' ?
                        showAllProduct()
                        :
                        <div>
                            <br />
                            <p class='fs-1 mt-4'>No Product Found...</p>
                        </div>
                }
                <br />
            </div>
        </div>
    );
};

export default SendForProduction;
