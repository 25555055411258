import React, { useEffect, useState } from 'react'
import { FaBorderAll, FaShoppingCart, FaUserCircle } from 'react-icons/fa'
import { MdDashboard } from 'react-icons/md'
import { RiMenu3Line, RiProfileLine, RiShutDownLine } from 'react-icons/ri'
import { useLogIn } from './LoginContext '
import { useLocation, useNavigate } from 'react-router-dom'
import { AiFillProduct } from 'react-icons/ai'
import axios from 'axios'
import { TbLanguageHiragana, TbProgressAlert } from 'react-icons/tb'
import { IoCloseSharp } from 'react-icons/io5'
import { AmbikaChefAPI } from './config'

const Navbar = ({ cart }) => {
    const [language , setLanguage] = useState('en')

    const [profile, setProfile] = useState()
    const { logOut } = useLogIn()
    const navigate = useNavigate()


    const handelLogOut = () => {
        localStorage.clear();
        navigate('/')
    }

    const ambikaChefUserToken = localStorage.getItem("ambikaChefUserToken");
    const ambikaChefUserId = localStorage.getItem("ambikaChefUserId");
    useEffect(() => {
        axios.get(`${AmbikaChefAPI}/vendor/profile`, { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } })
            .then((res) => {
                setProfile(res?.data?.data);
            })
            .catch((err) => {
                // console.log(err);
            })
    }, [ambikaChefUserToken, ambikaChefUserId])




    const location = useLocation();
    const isActiveLink = (link) => {
        return link === location.pathname;
    };

    const changeLanguage = () => { 
    const currentLanguage = localStorage.getItem('Ambikalanguage') || 'en';  
    const newLanguage = currentLanguage === 'en' ? 'hi' : 'en';  
    localStorage.setItem('Ambikalanguage', newLanguage);  
    setLanguage(newLanguage);
        axios
            .post(
                `${AmbikaChefAPI}/updateLanguage`,
                { UserId: ambikaChefUserId, lang: newLanguage },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => { 
                    window.location.reload();  
            })
            .catch((err) => { });
    };

    return (
        <>
            <div class='d-flex align-items-center justify-content-between' style={{ height: '70px', boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", position: 'fixed', top: '0', left: '0', width: '100%', zIndex: '10', background: 'white' }}>
                <div class='col-11 m-auto d-flex align-items-center gap-5 justify-content-between'>
                    <div class='d-flex align-items-center gap-5' >
                        <p data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"><RiMenu3Line class='fs-3 fw-bold' /></p>
                        <img src={require('./image/logo.png')} style={{ height: '50px' }} alt="" onClick={() => navigate('/orders')} />
                    </div>

                    <div class='d-flex gap-3' >
                    <button class='btn btn-outline-success p-0' onClick={changeLanguage}> <TbLanguageHiragana class='fs-1' /> </button>
                    </div>
                </div>
            </div>
            <div class="offcanvas offcanvas-start text-white" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ background: '#316f46' }}>

                <div class='d-flex justify-content-between text-white p-3'>
                    <img src={require('./image/logo.png')} style={{ height: '80px' }} alt="" />
                    <div class='fs-2' data-bs-dismiss="offcanvas" aria-label="Close"><IoCloseSharp /></div>
                </div>
                <div class="offcanvas-body p-0">
                    <div class='text-start p-3'>
                        <p class='fs-4 fw-semibold'>{profile?.name}</p>
                        <p class='fs-5'>{profile?.phone}</p>
                    </div>


                    <div class=''>
                        <hr class='my-2' />
                        <div style={isActiveLink('/orders') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} class='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/orders')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><AiFillProduct class='fs-5' /></div><div> <p class='fs-5 fw-semibold'>Orders</p></div>
                        </div> 
                        <hr class='my-2' />
                        <div style={isActiveLink('/profile') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} class='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/profile')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><RiProfileLine class='fs-5' /></div><div> <p class='fs-5 fw-semibold'>Profile</p></div>
                        </div>

                        <hr class='my-2' />
                        <div style={isActiveLink('/ ') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} class='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => handelLogOut()} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><RiShutDownLine class='fs-5' /></div><div> <p class='fs-5 fw-semibold'>Log-Out</p> </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar