import React, { createContext, useState, useContext, useEffect } from 'react';

const UserTypeContext = createContext();

export const useUserType = () => useContext(UserTypeContext);

export const UserTypeProvider = ({ children }) => {
  const [userType, setUserType] = useState(() => {
    // Check localStorage for 'ambikaUserType' value when initializing the state
    const storedUserType = localStorage.getItem('ambikaChefUserType');
    return storedUserType ? storedUserType : 'Branch';
  });
  useEffect(() => {
    // Update localStorage whenever userType changes
    localStorage.setItem('ambikaChefUserType', userType);
  }, [userType]);

  return (
    <UserTypeContext.Provider value={{ userType, setUserType }}>
      {children}
    </UserTypeContext.Provider>
  );
};





// import React, { createContext, useState, useContext } from 'react';

// const UserTypeContext = createContext();

// export const useUserType = () => useContext(UserTypeContext);

// export const UserTypeProvider = ({ children }) => {
//   const [userType, setUserType] = useState('Branch');

//   return (
//     <UserTypeContext.Provider value={{ userType, setUserType }}>
//       {children}
//     </UserTypeContext.Provider>
//   );
// };



