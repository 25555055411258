
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './camponents/LandingPage';
import Login from './camponents/Login';
import Navbar from './camponents/Navbar';
import { PrivateRoute } from './camponents/PrivateRoute';
import Profile from './camponents/Profile';
import ShowOrder from './camponents/ShowOrder';
import ProductionProductList from './camponents/ProductionProductList';
import OrdrNav from './camponents/OrdrNav';
import OrderList from './camponents/OrderList';
import OrderProcess from './camponents/OrderProcess';
import SendForProduction from './camponents/SendForProduction';
import SendForFoodReady from './camponents/SendForFoodReady';
import PandingPacking from './camponents/PandingPacking';
import FoodReadyReport from './camponents/FoodReadyReport';
import ProductionReadyReport from './camponents/ProductionReadyReport';
import PakingReadyReport from './camponents/PakingReadyReport';
import { useState } from 'react';


function App() {

  const [updateStatus, setUpdateStatus] = useState()

  const location = useLocation();
  const isActiveLink = (link) => {
    // console.log(location.pathname);
    return link === location.pathname;
  };

  return (
    <div className="App">
      {
        (isActiveLink('/') || isActiveLink('/log-in')) ? '' :
          <>
            <Navbar />
            <div style={{ height: '70px' }}></div>
            
            <OrdrNav update={setUpdateStatus}/>
          </>
      }
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/log-in' element={<Login />} />
        {/* <Route path='/orders' element={<PrivateRoute><ShowOrder /></PrivateRoute>} /> */}
        <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path='/production-product' element={<PrivateRoute><ProductionProductList /></PrivateRoute>} />

        <Route path='/order-place' element={<PrivateRoute><OrderList update={updateStatus} /></PrivateRoute>} />
        <Route path='/accepted-order' element={<PrivateRoute><OrderProcess update={updateStatus} /></PrivateRoute>} />
        <Route path='/send-for-production' element={<PrivateRoute><SendForProduction update={updateStatus}/></PrivateRoute>} />
        <Route path='/production-ready' element={<PrivateRoute><SendForFoodReady update={updateStatus}/></PrivateRoute>} />
        <Route path='/pending-packing' element={<PrivateRoute><PandingPacking update={updateStatus}/></PrivateRoute>} />
        <Route path='/food-ready-report' element={<PrivateRoute><FoodReadyReport update={updateStatus}/></PrivateRoute>} />
        <Route path='/production-ready-report' element={<PrivateRoute><ProductionReadyReport update={updateStatus}/></PrivateRoute>} />
        <Route path='/packing-ready-report' element={<PrivateRoute><PakingReadyReport update={updateStatus}/></PrivateRoute>} />


         


      </Routes>
    </div>
  );
}

export default App;
