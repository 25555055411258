import axios from 'axios'
import React, {useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { AmbikaChefAPI } from './config'
 
const Login = () => {
  const [number, setNumber] = useState()
  const [otpToggel, setOtpToggel] = useState(false)
  const [otp, setOtp] = useState()  
  const navigate = useNavigate()  
  const maskNumber = (num) => {
    const numStr = num?.toString();
    const maskedSection = numStr?.slice(2, -4)?.replace(/./g, '*');
    return numStr?.slice(0, 2) + maskedSection + numStr?.slice(-4);
  }; 

 const checkNumber = () =>{
    axios.post(`${AmbikaChefAPI}/login`,{phone : number,roleid : '2'})
    .then((res)=>{
      if(res?.data?.success){
        Swal.fire({ 
          text: `${res?.data?.message}`, 
        });
        setOtpToggel(true)
      }else{
        Swal.fire({ 
          text: `${res?.data?.message}`, 
        });
      } 
    })
    .catch((err)=>{ })
 }

  const chekOtp=()=>{
    axios.post(`${AmbikaChefAPI}/checkOTP`,{phone : number,roleid : '2',otp : otp})
    .then((res)=>{
      if(res?.data?.success){
        Swal.fire({ 
          text: `${res?.data?.message}`, 
        });
        localStorage.setItem("ambikaChefUserToken",res?.data?.token); 
        localStorage.setItem("ambikaChefUserId",res?.data?.UserId);   
        navigate('/orders')
      }else{
        Swal.fire({ 
          text: `${res?.data?.message}`, 
        });
      } 
    })
    .catch((err)=>{
      // console.log(err);
    })
  }

 
 

 

 

  return (
    <>
      <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh', width: '100%' }}>
        <div class='col-lg-6 col-md-8 rounded-4 p-4' style={{ boxShadow: ' rgba(17, 12, 46, 0.15) 0px 48px 100px 0px' }}>
          <div class='col-11 m-auto text-start '>
            <img src={require('./image/logo.png')} style={{width:'150px'}}  alt="" />
            <p class='fs-1 text-start mt-3'>Please Login to Continue</p> 
            <div class="form__group field">
              <input type="input" maxLength={10} class="form__field" value={number} placeholder='Number' name='number' onChange={(e) => setNumber(e.target.value)} />
              <label for="number" class="form__label">Enter Your Phone Number </label>
            </div> 
            <button class='btn btn-success form-control rounded-4 mt-4' disabled={number?.length == 10 ? false : true} onClick={() => checkNumber()} >Continue</button>
          </div>
        </div>

        {
          otpToggel &&
          <div class='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.7)', position: "fixed", top: '0', left: '0' }}>
            <div class='col-lg-6 col-md-8 bg-white rounded-4 p-4' >
              <div class='col-11 m-auto text-start '>
            <img src={require('./image/logo.png')} style={{width:'150px'}}  alt="" />

                <p class='fs-1 text-start mt-3'>Verification Code</p>

                <p class='text-secondary'><span>We have sent the code verification to</span>    <span class='fs-5 text-black'> {maskNumber(number)}</span> <span class='fs-5' style={{ color: "green", textDecoration: 'underline',cursor:'pointer' }} onClick={() => setOtpToggel(false)}> Change ? </span> </p>

                <div class="form__group field">
                  <input type="input" maxLength={6} class="form__field" value={otp} placeholder='OTP' name='otp' onChange={(e) => setOtp(e.target.value)} />
                  <label for="otp" class="form__label">OTP</label>
                </div>
                <div class='d-flex gap-3 align-items-center'>
                  <button class='btn btn-success form-control rounded-4 mt-4' onClick={()=>chekOtp()} >Confirm</button>
                  <button class='btn btn-outline-success form-control rounded-4 mt-4' onClick={()=>checkNumber()}  >Resend</button>

                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default Login