import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LogInProvider } from './camponents/LoginContext ';
import { UserTypeProvider } from './camponents/UserTypeContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LogInProvider>
        <UserTypeProvider> 
        <App />
        </UserTypeProvider>
      </LogInProvider> 
    </BrowserRouter>
  </React.StrictMode>
);

 
serviceWorkerRegistration.register();
reportWebVitals();

