import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AmbikaChefAPI } from './config'
import { useNavigate } from 'react-router-dom'

const OrdrNav = ({update}) => {
    
    const [getOrderList, setGetOrderList] = useState()
    const [showOrderList, setShowOrderList] = useState()

    const [selectOrderList, setSelectOrderList] = useState(null)

    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');

    const navigate = useNavigate()


    const itemStatu = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/chefDashboard`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setGetOrderList(res?.data?.data)
            })
            .catch((err) => { });
    }
    useEffect(() => {
        if (update) {
          update(itemStatu); // Pass the itemStatu function once
        }
      }, [update]);


    useEffect(() => {
        itemStatu()
    }, [ambikaChefUserToken, ambikaChefUserId]);

    useEffect(() => {
        if(getOrderList){ 
            setShowOrderList(getOrderList)
            const firstNonZeroEntry = Object.entries(getOrderList)?.find(([key, value]) => value.totCount > 0);
            if (firstNonZeroEntry) { 
                setSelectOrderList(firstNonZeroEntry[0]);
              }
        }
    }, [getOrderList])


    const scrollRight = () => {
        const container = document.querySelector('.orderOption');
        container.scrollBy({ left: 200, behavior: 'smooth' });
    };

    const scrollLeft = () => {
        const container = document.querySelector('.orderOption');
        container.scrollBy({ left: -200, behavior: 'smooth' });
    };
  return (
    <div>
        <div className="col-lg-6 col-md-8 col-11 m-auto "  >
                <div className='d-flex align-items-center gap-2 mt-3 col-12 justify-content-between' >
                    <button class="btn btn-outline-success px-1 p-0 " onClick={scrollLeft}><i class="bi bi-arrow-left-short"></i></button>
                    <div class='  orderOption '>
                        {
                            showOrderList?.orderPlace?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "orderPlace" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('order-place')}>
                                <p class='text-nowrap'>{showOrderList?.orderPlace?.name}</p>
                                <div class='orderNotification'>{showOrderList?.orderPlace?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.acceptedOrder?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "acceptedOrder" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('accepted-order')}>
                                <p class='text-nowrap'>{showOrderList?.acceptedOrder?.name}</p>
                                <div class='orderNotification'>{showOrderList?.acceptedOrder?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.sendForProduction?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "sendForProduction" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('send-for-production')}>
                                <p class='text-nowrap'>{showOrderList?.sendForProduction?.name}</p>
                                <div class='orderNotification'>{showOrderList?.sendForProduction?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.productionReady?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "productionReady" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('production-ready')}>
                                <p class='text-nowrap'>{showOrderList?.productionReady?.name}</p>
                                <div class='orderNotification'>{showOrderList?.productionReady?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.pendingPacking?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "pendingPacking" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('pending-packing')}>
                                <p class='text-nowrap'>{showOrderList?.pendingPacking?.name}</p>
                                <div class='orderNotification'>{showOrderList?.pendingPacking?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.foodReady_report?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "foodReady_report" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('food-ready-report')}>
                                <p class='text-nowrap'>{showOrderList?.foodReady_report?.name}</p>
                                <div class='orderNotification'>{showOrderList?.foodReady_report?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.productionReady_report?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "productionReady_report" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('production-ready-report')}>
                                <p class=' text-nowrap'>{showOrderList?.productionReady_report?.name}</p>
                                <div class='orderNotification'>{showOrderList?.productionReady_report?.totCount}</div>
                            </div>
                        }
                        {
                            showOrderList?.packingReady_report?.totCount !== 0 &&
                            <div class='orderOptiondiv' style={selectOrderList == "packingReady_report" ?  {color:"#1a8855",fontWeight:"bold", borderBottom:"2px solid #1a8855"}:{color:"black",fontWeight:"normal"}} onClick={() => navigate('packing-ready-report')}>
                                <p class='text-nowrap'>{showOrderList?.packingReady_report?.name}</p>
                                <div class='orderNotification'>{showOrderList?.packingReady_report?.totCount}</div>
                            </div>
                        }

                    </div>

                    <button class="btn btn-outline-success px-1 p-0" onClick={scrollRight}><i class="bi bi-arrow-right-short"></i></button>

                </div>

            </div>
    </div>
  )
}

export default OrdrNav