import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AmbikaChefAPI } from './config';

const OrderList = ({update}) => {
    const [placeOrder, setPlaceOrder] = useState([]);
    const [openSection, setOpenSection] = useState(null); // To track the currently open section
    const [showOrderAcceptRemark, setShowOrderAcceptRemark] = useState(false)
    const [acceptOrderDetails, setAcceptOrderDetails] = useState()
    const [acceptOrderRemark, setAcceptOrderRemark] = useState()
    const ambikaChefUserToken = localStorage.getItem('ambikaChefUserToken');
    const ambikaChefUserId = localStorage.getItem('ambikaChefUserId');

    const getPlaceOrderList = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/orderPlaceProductList`,
                { UserId: ambikaChefUserId },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setPlaceOrder(res?.data?.data);

            })
            .catch((err) => {
                // Handle error
            });
    }


    useEffect(() => {
        getPlaceOrderList()
    }, [ambikaChefUserToken, ambikaChefUserId]);

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };

    const handelAcceptOrder = (orderDetails) => {
        let productOrderId = [];
        orderDetails?.product_detail?.forEach((product) => {
            productOrderId.push(product.orderProduct_id);

        });
        setAcceptOrderDetails((prevDetails) => ({
            ...prevDetails,
            orderId: orderDetails?.order_id,
            orderProductId: productOrderId,
        }));
        setShowOrderAcceptRemark(true)
    };



    const showAllProduct = (placeOrder) => {
        return placeOrder?.map((order, index) => (
            <div key={index} className="text-start mt-4">
                <div className="d-flex align-items-center justify-content-between" style={{ fontSize: '14px' }}>
                    <p class='fw-semibold'>{order.pickup_date} | {order.pickup_time}</p>
                    <p className="fw-semibold"># {order.order_id}</p>
                </div>
                {order.product_detail?.map((cat, catInd) => {
                    return (
                        <div class='boxSadow1 rounded-3 p-2'>
                            <div class='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className="fw-semibold ">{cat?.productName} <span className=' text-secondary' style={{ fontSize: '12px' }}>{cat?.size_name}</span></p>
                                </div>
                                <div>
                                    <p className="fw-semibold "> <span>{cat?.product_qty}</span> </p>
                                </div>
                            </div>
                            <div className=' border-top'>
                                <div className="d-flex gap-2 align-items-center mt-2">
                                    {cat?.is_urgent !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Urgent
                                        </p>
                                    )}
                                    {cat?.is_parcel !== 0 && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Packing
                                        </p>
                                    )}
                                    {cat?.eggless_option !== '' && (
                                        <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                            Less Cream
                                        </p>
                                    )}
                                </div>
                                {(cat?.shap_option !== "" || cat?.message_option !== "") &&
                                    <div className="d-flex gap-2 align-items-center mt-2">
                                        {
                                            cat?.shap_option !== "" && (
                                                <p class='text-secondary'>{cat?.shap_option}</p>
                                            )
                                        }
                                        {
                                            (cat?.shap_option !== "" && cat?.message_option !== "") && (
                                                <p class='text-secondary'>|</p>
                                            )
                                        }
                                        {
                                            cat?.message_option !== "" && (
                                                <p class='text-secondary'>{cat?.message_option}</p>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    )
                })}

                {/* {order.product_detail.map((cat, catIndex) => (
                    <div key={catIndex} className="border rounded-3 border-1 mt-2 bg-light">
                        <div
                            className="p-2 d-flex align-items-center justify-content-between"
                            data-bs-toggle="collapse"
                            href={`#orderCatList${index}-${catIndex}`}
                            role="button"
                            aria-expanded={openSection === `${index}-${catIndex}`}
                            aria-controls={`orderCatList${index}-${catIndex}`}
                            onClick={() => toggleSection(`${index}-${catIndex}`)}
                        >
                            <p className="fs-5 fw-semibold">{cat.category_name}</p>
                            <p>
                                <i className={`bi ${openSection === `${index}-${catIndex}` ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                            </p>
                        </div>
                        <div className={`collapse ${openSection === `${index}-${catIndex}` ? 'show' : ''}`} id={`orderCatList${index}-${catIndex}`}>
                            {cat.product_detail.map((el, productIndex) => (
                                <div key={productIndex} className="border-top p-2">
                                    <div className="mx-2">
                                        <p className="fw-semibold fs-5">{el?.productName}</p>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <p>Weight:</p>
                                            <p>{el?.size_name}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Shape:</p>
                                            <p>{el?.shap_option}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Less Cream:</p>
                                            <p>{el?.eggless_option}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>Message on the Cake:</p>
                                            <p>{el?.message_option}</p>
                                        </div>
                                        <div className="d-flex gap-3 align-items-center">
                                            {el?.is_urgent !== 0 && (
                                                <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                                    Urgent
                                                </p>
                                            )}
                                            {el?.is_parcel !== 0 && (
                                                <p style={{ background: '#FF6969', borderRadius: '20px', padding: '0px 10px', fontSize: '14px', color: 'white' }}>
                                                    Packing
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))} */}

                <button key={index} style={{ fontSize: '14px' }} className="btn btn-success form-control mt-3 rounded-5 fw-semibold" onClick={() => handelAcceptOrder(order)}>Accept Order</button>
            </div>
        ));
    };
    const handelCallAccept = () => {
        axios
            .post(
                `${AmbikaChefAPI}/chef/acceptOrderProduct`,
                { UserId: ambikaChefUserId, ...acceptOrderDetails, remarks: acceptOrderRemark },
                { headers: { Authorization: `Bearer ${ambikaChefUserToken}` } }
            )
            .then((res) => {
                setShowOrderAcceptRemark(false)
                Swal.fire({
                    icon: "success",
                    title: "Great...",
                    text: `${res?.data?.message}`,
                });
                update()
                getPlaceOrderList()

            })
            .catch((err) => {
                // Handle error
            });
    }

    return (
        <div>
            {
                showOrderAcceptRemark === true &&
                <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', position: 'fixed', top: '0', left: '0', zIndex: '10' }} class='d-flex align-items-center justify-content-center'>
                    <div class='p-3 col-md-4 col-10 bg-light rounded-4'>
                        <p class='fs-2'>Remarks</p>
                        <input type="text" placeholder='Write Remarks' class='form-control mt-2' onChange={(e) => setAcceptOrderRemark(e.target.value)} />
                        <div class='d-flex gap-3'>
                            <button class='btn btn-success mt-3 form-control rounded-5' style={{ fontSize: '14px' }} onClick={() => handelCallAccept()}> Save</button>
                            <button class='btn  btn-outline-danger mt-3 form-control rounded-5' style={{ fontSize: '14px' }} onClick={() => setShowOrderAcceptRemark(false)}> Close</button>
                        </div>
                    </div>

                </div>
            }
            <div className="col-lg-6 col-md-8 col-11 m-auto mt-4">
                {
                    placeOrder != '' ?
                        showAllProduct(placeOrder)
                        :
                        <div>
                            <br />
                            <p class='fs-1 mt-4'>No Product Found...</p>
                        </div>
                }
                <br />
            </div>
        </div>
    );
};

export default OrderList;
